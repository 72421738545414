<template>
  <div>
    <a-row>
      <a-col
        :xs="{ span: 18, offset: 8 }"
        :sm="{ span: 18, offset: 5 }"
        :md="{ span: 16, offset: 5 }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 8 }"
        :xxl="{ span: 12, offset: 8 }"
      >
        <bases-selector style="margin: 10px 0px 25px 15px;" />
      </a-col>
    </a-row>
    <a-row>
      <DronesManagement />
    </a-row>
  </div>
</template>

<script>
import BasesSelector from "../Bases/BaseSelector";
import DronesManagement from "./DronesManagement";
import Vue from "vue";
import { Breadcrumb, Icon } from "ant-design-vue";

Vue.use(Breadcrumb);
Vue.use(Icon);

export default {
  name: "basesEdit",
  components: {
    BasesSelector,
    DronesManagement,
  },
};
</script>
