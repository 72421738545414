<template>
  <div class="drones-container" v-if="baseId !== null && dataLoaded">
    <a-row style="marginBottom: 20px;">
      <a-row>
        <div class="table-gadgets">
          <a-col v-if="isFPManager" :span="2" :offset="2">
            <a-button
              :disabled="disableAddDrone"
              class="btn btn-primary"
              type="primary"
              @click="addDrone()"
            >
              {{ $t("form.add") }}
            </a-button>
          </a-col>
          <a-col :span="8" :offset="isFPManager ? 10 : 2">
            <a-input-search
              class="input-search-table"
              placeholder="Chercher un drone..."
              v-model="searchDrone"
            />
          </a-col>
        </div>
      </a-row>
      <a-row type="flex" style="marginTop: 20px;">
        <a-col :span="20" :offset="2">
          <a-table
            class="droneList-table"
            :columns="columns"
            :dataSource="resultDroneSearch"
            rowKey="id"
            :pagination="pagination"
            @change="onTableChange"
          >
            <template slot="serialNumber" slot-scope="text, record">
              <a-form-item
                v-if="editingDrone && record.id === editingDrone.id"
                :validate-status="
                  invalidFields.indexOf('serialNumber') === -1 ? '' : 'error'
                "
              >
                <a-input
                  style="margin: -5px 0"
                  :value="editingDrone.serialNumber"
                  @change="
                    (e) => handleChange('serialNumber', e.target.value)
                  "
                  @keyup.enter="() => save(editingDrone)"
                  ref="serialNumber"
                />
              </a-form-item>
              <template v-else>{{ text }}</template>
            </template>
            <template
              v-for="col in [
                'firmwareVersion',
                'model',
                'matriculation'
              ]"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-form-item
                  v-if="editingDrone && record.id === editingDrone.id"
                  :validate-status="
                    invalidFields.indexOf(col) === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    style="margin: -5px 0"
                    :value="editingDrone[col]"
                    @change="(e) => handleChange(col, e.target.value)"
                    v-on:keyup.enter="() => save(editingDrone)"
                    :ref="col"
                  />
                </a-form-item>
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <span v-if="editingDrone && record.id === editingDrone.id">
                <a-row class="btn-form">
                  <a-col :span="12">
                    <a-button
                      @click="() => save(editingDrone)"
                      type="link"
                      class="btn-editing"
                      :title="$t('form.save')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="check-square"
                      />
                    </a-button>
                  </a-col>
                  <a-col :span="12">
                    <a-button
                      @click="() => cancel()"
                      type="link"
                      class="btn-editing btn-cancel"
                      :title="$t('form.cancel')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="close-square"
                      />
                    </a-button>
                  </a-col>
                </a-row>
              </span>
              <span v-if="editingDrone === null">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="edit" @click="edit(record)">
                      <a-icon type="edit" />{{ $t("form.edit") }}
                    </a-menu-item>
                    <a-menu-item key="delete" @click="confirmDelete(record)">
                      <a-icon type="delete" />{{
                        $t("form.delete")
                      }}</a-menu-item
                    >
                  </a-menu>
                  <a-button style="margin-left: 8px">
                    <a-icon type="more" />
                  </a-button>
                </a-dropdown>
              </span>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Modal,
  Form,
  Input,
} from "ant-design-vue";
import { isFPManager } from "../../../utils/userAuth";
import { baseTypes } from "../../../store/mutation-types";

Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Modal);

let basesUnwatcher = null;
let currentBaseIdUnwatcher = null;

export default {
  name: "droneList",
  computed: {
    isFPManager() {
      return isFPManager();
    },
    disableAddDrone() {
      return this.editingDrone !== null;
    },
    baseId() {
      return this.$store.state.bases.currentBaseId;
    },
    resultDroneSearch() {
      if (this.searchDrone) {
        return this.drones.filter((item) => {
          return this.searchDrone
            .toLowerCase()
            .split(" ")
            .every((v) => {
              item.serialNumber.toLowerCase().includes(v)
              item.matriculation.toLowerCase().includes(v)
              item.model.toLowerCase().includes(v)
              item.firmwareVersion.toLowerCase().includes(v)
            });
        });
      } else {
        return this.drones;
      }
    },
    columns() {
      const columns = [
        {
          title: this.$t("drones.serialNumber"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: "10%",
          scopedSlots: { customRender: "serialNumber" },
        },
        {
          title: this.$t("drones.firmwareVersion"),
          dataIndex: "firmwareVersion",
          key: "firmwareVersion",
          width: "10%",
          scopedSlots: { customRender: "firmwareVersion" },
        },
        {
          title: this.$t("drones.model"),
          dataIndex: "model",
          key: "model",
          width: "10%",
          scopedSlots: { customRender: "model" },
        },
        {
          title: this.$t("drones.matriculation"),
          dataIndex: "matriculation",
          key: "matriculation",
          width: "10%",
          scopedSlots: { customRender: "matriculation" },
        },
      ];
      if (this.isFPManager) {
        columns.push({
          dataIndex: "action",
          width: "5%",
          scopedSlots: { customRender: "action" },
        });
      }
      return columns;
    },
  },
  data() {
    return {
      eventBus: new Vue(),
      dataLoaded: false,
      editingDrone: null,
      drones: [],
      searchDrone: null,
      pagination: {
        defaultCurrent: 1,
        current: 1,
      },
      invalidFields: [],
    };
  },
  created() {
    basesUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === baseTypes.FETCH_BASES) {
        this.refreshDronesFromStore();
        this.dataLoaded = true;
      }
    });

    currentBaseIdUnwatcher = this.$store.watch(
      (state) => state.bases.currentBaseId,
      () => {
        this.refreshDronesFromStore();
        this.dataLoaded = true;
        this.eventBus.$emit("base-changed");
      }
    );
  },
  mounted() {
    if (this.baseId) {
      if (this.$store.state.bases.lastFetch === null) {
        this.$store.dispatch("fetchBases");
      } else {
        this.refreshDronesFromStore();
        this.dataLoaded = true;
      }
    }
  },
  beforeDestroy() {
    if (currentBaseIdUnwatcher) {
      currentBaseIdUnwatcher();
    }

    if (basesUnwatcher) {
      basesUnwatcher();
    }
  },
  methods: {
    onTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
    },
    refreshDronesFromStore() {
      if (this.baseId !== null) {
        this.drones = JSON.parse(
          JSON.stringify(this.$store.getters.getBaseById(this.baseId).drones)
        );
      }
    },
    handleChange(field, value) {
      if (this.editingDrone) {
        this.editingDrone[field] = value;
      }
    },
    validateDrone(drone) {
      let invalidFields = [];
      if (drone.serialNumber === "") {
        invalidFields.push("serialNumber");
      }
      if (drone.firmwareVersion === "") {
        invalidFields.push("firmwareVersion");
      }
      if (drone.model === "") {
        invalidFields.push("model");
      }
      if (drone.matriculation === "") {
        invalidFields.push("matriculation");
      }
      this.invalidFields = invalidFields;
      return invalidFields.length === 0;
    },
    cancel() {
      this.editingDrone = null;
      this.invalidFields = [];
      var index = this.drones.findIndex((d) => d.id === "new");
      if (index > -1) {
        this.drones.splice(index, 1);
      }
    },
    addDrone() {
      this.pagination.current = 1;

      const newDrone = {
        id: "new",
        serialNumber: "",
        firmwareVersion: "",
        model: "",
        matriculation: "",
      };

      this.drones.unshift(newDrone);
      this.editingDrone = newDrone;
      this.$nextTick(() => {
        this.$refs["serialNumber"].focus();
      });
    },
    save(drone) {
      if (Object.hasOwnProperty.call(drone, "id")) {
        if (this.validateDrone(drone)) {
          let isNew = drone.id === "new";
          let promise;
          if (isNew) {
            promise = this.$store.dispatch("addDrone", {
              base: this.$store.getters.getBaseById(this.baseId),
              drone,
            });
          } else {
            promise = this.$store.dispatch("updateDrone", {
              base: this.$store.getters.getBaseById(this.baseId),
              drone,
            });
          }
          promise.then(() => {
            this.editingDrone = null;
            this.refreshDronesFromStore();
          });
        }
      }
    },
    edit(target) {
      this.editingDrone = { ...target };
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store
            .dispatch("deleteDrone", {
              base: this.$store.getters.getBaseById(this.baseId),
              drone: target,
            })
            .then(() => this.refreshDronesFromStore());
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
 .btn-form {
    height: 2.5rem;
    width: 3.75rem;
    font-size: 1.5rem;
  }

  .btn-editing{
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #f0de00
  }

  .btn-cancel {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: red;
  }
</style>
