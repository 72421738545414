<template>
  <div>
    <a-row
      type="flex"
      align="middle"
      :gutter="16"
    >
      <a-col >
        <label class="label-base">
          {{ $t('bases.selectedBase') }}: 
        </label>
      </a-col>
      <a-col class="column-select-base">
        <a-select
          style="marginTop: 8px"
          @change="onBaseChanged"
          :value="currentBaseId"
        >
          <a-select-option
            v-for="base in bases"
            v-bind:key="base.id"
            :value="base.id"
          >
            {{ base.baseName }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Vue from 'vue'
import { Col, Row, Input, Select, Button } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Row)
Vue.use(Input)
Vue.use(Select)
Vue.use(Button)

export default {
  name: 'bases-selector',
  computed: {
    bases () {
      return this.$store.state.bases.bases
    },
    currentBaseId () {
      return this.$store.state.bases.currentBaseId
    }
  },
  methods: {
    onBaseChanged (id) {
      this.$store.dispatch('setCurrentBaseId', id)
    }
  },
  mounted () {
    if (this.$store.state.bases.lastFetch === null) {
      this.$store.dispatch('fetchBases')
    }
  }
}
</script>

<style lang="less" scoped>
.label-base {
  font-family: "Lato Bold";
  font-size: 20px;
}
.column-select-base {
  margin-top: -1%;
}
.ant-select {
  width: 200px;
}
</style>